$(function() {
  $('a').on('click', function(event) {
    if(this.hash !== "") {
      event.preventDefault();

      var hash = this.hash;

      if($(this).hasClass('nav-link')) {
        $('#navigation-menu').collapse('hide');
      }

      $('html, body').animate({
        scrollTop: $(hash).offset().top - 84
      }, 800, function() {
        history.replaceState(null, null, document.location.pathname + hash);
      });
    }
  });
});